<template>
  <b-sidebar
      id="request-leave-side-bar"
      :visible="isEditLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-leave-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Request Leave Edit
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>
        <div class="p-2">
          <validation-observer ref="createShiftForm">
            <b-form @submit.prevent>
              <b-row>
                <b-col md="12">
                  <h5 class="mb-2 cursor-pointer">
                    Leave Type</h5>
                </b-col>

                <b-col  md="6">
                  <b-form-checkbox
                      v-model="selectedLeaveType"
                      class="float-left"
                      value="timeOff"
                  >
                    Time Off
                  </b-form-checkbox>
                  <b-form-checkbox
                      v-model="selectedLeaveType"
                      class="float-right"
                      value="dayOff"
                  >
                    Day Off
                  </b-form-checkbox>


                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="selectedLeaveType === 'timeOff'">
                <b-col cols="12" md="6">
                  <label>Date</label>

                  <b-form-group>
                    <flat-pickr
                        v-model="selectedStartDate"
                        :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d', minDate: new Date()}"
                        class="form-control"
                    />
                  </b-form-group>

                </b-col>
                <b-col cols="12" md="3">
                  <label>Start Time</label>
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Start Time"
                        rules="required"
                    >
                      <flat-pickr
                          v-model="selectedStartTime"
                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',  }"
                          class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <label>End Time</label>
                  <validation-provider
                      #default="{ errors }"
                      name="End Time"
                      rules="required"
                  >
                    <b-form-group>
                      <flat-pickr
                          v-model="selectedEndTime"
                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                          class="form-control"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md = 12>
                  <label>Reason</label>
                  <b-form-textarea
                      v-model="selectedReasonForTimeOff"
                      id="textarea-default"
                      placeholder="Textarea"
                      rows="3"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2" v-if="selectedLeaveType === 'dayOff'">
                <b-col cols="12" md="6">
                  <label>Start Date</label>

                  <b-form-group>
                    <flat-pickr
                        v-model="selectedDayOffStartDate"
                        :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',  minDate: new Date()}"
                        class="form-control"
                    />
                  </b-form-group>

                </b-col>
                <b-col cols="12" md="6">
                  <label>End Date</label>

                  <b-form-group>
                    <flat-pickr
                        v-model="selectedDayOffEndDate"
                        :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',  minDate: new Date()}"
                        class="form-control"
                    />
                  </b-form-group>

                </b-col>

                <b-col md = 12>
                  <label>Reason</label>
                  <b-form-textarea
                      v-model="selectedReasonForDayOff"
                      id="textarea-default"
                      placeholder="Textarea"
                      rows="3"
                  />
                </b-col>

              </b-row>


              <div class="d-flex mt-3">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    @click="editLeaveForm()"
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="text-primary ml-1"
                    type="button"
                    variant="outline-white"
                    @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {

  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BTable,
  BCard,
  BAvatar,
  BLink,
  BBadge,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BFormSelect,
  BPagination,
  BInputGroupAppend, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BOverlay,BFormTextarea


} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import settings from '@/apis/modules/settings'
import Filter from '@/mixins/FilterMixin'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import leave from '@/apis/modules/leave'
import moment from 'moment'

export default {
  name: 'requestLeave',

  components: {
    flatPickr, BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroup,
    BButton, BFormDatepicker, BFormCheckbox, vSelect, VuePerfectScrollbar, draggable, BRow, BCol, BInputGroupPrepend,
    // Form Validation
    ValidationProvider, ValidationObserver, BCard,
    BAvatar, BLink, BBadge, BCardBody, BCardHeader, BImg, BMedia, BTable, BFormSelect, BPagination, BInputGroupAppend,
    BDropdown,
    BDropdownItem, BListGroup, BListGroupItem, BOverlay, VBTooltip,BFormTextarea
  },
  mixins: [Filter, MomentMixin],

  directives: {
    Ripple
  },
  model: {
    prop: 'isEditLeaveSidebarActive',
    event: 'update:is-edit-leave-sidebar-active'
  },
  props: {
    isEditLeaveSidebarActive: {
      type: Boolean,
      required: true
    },


  },
  data () {
    return {
      selectedReason:'',
      selectedReasonForTimeOff:'',
      selectedReasonForDayOff:'',
      test:'',
      selectedStartDate:'',
      selectedDayOffStartDate:'',
      selectedDayOffEndDate:'',
      selectedStartTime:'',
      selectedEndTime:'',
      selectedLeaveType:'',
      tableLoading:false,
      filters: {},
      selected: [],
      selectedCheck: true,
      leaveData:'',

      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      selectedOption: {
        title: 'All',
        value: 1
      },
    }
  },
  watch:{

  },
  methods: {
    hide(){
      this.$emit('requestLeave')
    },
    resetForm () {

    },
    async setData(data){
      try {
        this.leaveData ={}
        this.leaveData = await data
       if (this.leaveData.is_day === 0){

         this.selectedLeaveType ='timeOff'
         this.selectedStartDate = this.momentFormat(this.leaveData.start,'YYYY-MM-DD')
         this.selectedStartTime = this.momentFormat(this.leaveData.start,'H:i')
         this.selectedEndTime = this.momentFormat(this.leaveData.end,' H:i')
         this.selectedReasonForTimeOff = this.leaveData.reason

       }else {
         this.selectedLeaveType ='dayOff'
         this.selectedDayOffStartDate = this.momentFormat(this.leaveData.start,'YYYY-MM-DD H:i')
         this.selectedDayOffEndDate = this.momentFormat(this.leaveData.end,' YYYY-MM-DD H:i')
         this.selectedReasonForDayOff = this.leaveData.reason
       }

      }catch (error){
        this.convertAndNotifyError(error)
      }

    },

    async editLeaveForm () {
      try {
        this.tableLoading = true


        if (this.selectedLeaveType === 'timeOff'){
          let payload = {
            start: moment(this.selectedStartDate + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss'),
            end: moment(this.selectedStartDate + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss'),

            is_day:false,
            reason:this.selectedReasonForTimeOff
          }
          await  leave.editLeave( this.leaveData.id, payload)
        }else {
          let payload = {
            start: moment(this.selectedDayOffStartDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            end: moment(this.selectedDayOffEndDate ).utc().format('YYYY-MM-DD HH:mm:ss'),
            is_day:true,
            reason:this.selectedReasonForDayOff

          }
          await  leave.requestLeave(payload)
        }

        this.$emit('requestEditDataRefresh')
        this.$emit('editSideBarClose')

        this.showSuccessMessage('Request Leave Successful ')
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },

  },
  watch: {
    isEditLeaveSidebarActive(val){
      if(val == false){
         this.selectedLeaveType = null
         this.selectedStartDate = null
         this.selectedStartTime = null
         this.selectedEndTime = null
         this.selectedReasonForTimeOff = null

         this.selectedDayOffStartDate = null
         this.selectedDayOffEndDate = null
         this.selectedReasonForDayOff = null
      }
    }
  },
  mounted () {

  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#request-leave-side-bar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  //.table thead th {
  //  border-bottom: 2px solid #ebe9f1;
  //  visibility: hidden !important;
  //}
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

